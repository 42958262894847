<template>
  <div v-loading="loading" class="MediaOutletsList">
    <div v-if="filteredOutlets.length" class="columns is-multiline">
      <div v-for="[countryIso, mediaOutlets] in filteredOutlets" :key="countryIso" class="column is-12">
        <div class="mediaOutletCountry">
          <div class="mediaOutletCountry__name is-flex is-aligned-middle">
            <div v-if="countryIso !== 'x_uncategorized'" class="icon is-large">
              <CountryFlag :country="countryIso" />
            </div>

            <RoundIcon
              v-else
              size="large"
              icon-class="hf-lg"
              type="globe"
            />

            <span class="has-text-weight-bold is-size-4">
              {{ countryIso === 'x_uncategorized' ? $t('general.uncategorized') : getCountryByIso2(countryIso).name }}
            </span>
          </div>

          <div class="mediaOutlets tags border-grey-light p-a-s is-aligned-top">
            <MediaOutletsListItem
              v-for="outlet in mediaOutlets"
              :key="outlet.id"
              :outlet="outlet"
              :is-removing="isRemoving === outlet.id"
              @delete="removeOutlet"
            />

            <VButton class="is-primary" @click="$emit('add-new', { tier })">
              {{ $t('forms.add_new') }}
            </VButton>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="loading">
      <MediaOutletPlaceholder />
    </div>

    <div v-else class="message is-warning">
      <div class="message-body">
        {{ $t('warnings.no_outlets_assigned_to_brand_with_filters') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import CountryFlag from '@hypefactors/shared/js/components/core/CountryFlag.vue'
import VButton from '@hypefactors/shared/js/components/core/VButton.vue'

import RoundIcon from '@/components/core/RoundIcon.vue'

import MediaOutletsListItem from '@/pages/settings/brand/MediaOutletsListItem.vue'
import MediaOutletPlaceholder from '@/pages/settings/brand/MediaOutletPlaceholder.vue'

export default {
  components: {
    CountryFlag,
    VButton,
    RoundIcon,
    MediaOutletsListItem,
    MediaOutletPlaceholder
  },

  props: {
    tier: {
      type: String,
      default: 'aplus'
    },
    outlets: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isRemoving: false
    }
  },

  computed: {
    ...mapGetters(['getFilter', 'getCountryByIso2']),

    ...mapGetters('globalFilters', ['selectedCountries']),

    filteredOutlets () {
      const outlets = Object.entries(this.outlets)

      const selectedCountries = this.selectedCountries

      if (selectedCountries.length === 0) {
        return outlets
      }

      return outlets.filter(([key, values]) => selectedCountries.includes(key))
    }
  },

  methods: {
    async removeOutlet (outlet) {
      const tierTransPath = this.tier === 'aplus' ? 'tier_a' : 'tier_b'

      const tier = this.$t('components.media_outlet_tier_manager.' + tierTransPath)

      try {
        await this.$confirm(this.$t('components.media_outlet_tier_manager.confirm_tier_removal', { outlet: outlet.name, tier }))
      } catch (err) {
        return
      }

      this.isRemoving = outlet.id

      const payload = {
        outlet_id: outlet.id,
        tier: 'no-tier'
      }

      return this.$api.post(`/brands/${this.activeBrandId}/tiers`, payload)
        .then(response => {
          this.isRemoving = false

          this.$notify.success({
            title: this.$t('general.success'),
            message: this.$t('success.media_outlet_removed_from_brand')
          })

          this.$emit('update')
        })
        .catch(error => {
          this.isRemoving = false

          this.$displayRequestError(error, this.$t('errors.remove_media_outlet_from_brand'))
        })
    }
  }
}
</script>

<style lang='scss'>
@import '@/assets/styles/vueStyleUtils.scss';
</style>
